import React, { useState, useEffect, useRef } from 'react';
import { FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ onLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  // Toggles the dropdown menu for user options
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handles logout
  const handleLogout = () => {
    onLogout();
    localStorage.removeItem('userData');
    navigate('/login');
  };

  // Fetch user data from local storage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userData'));
    setUserData(user);
  }, []);

  // Closes dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-navy text-white p-3 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div></div>
        {/* Title */}
        <h1 className="text-xl font-bold ">RAMAKUMARI TRADES </h1>

        {/* Right-side Icons and Profile */}
        <div className="flex items-center space-x-6">
          {/* Notification Icon */}
          {/* <FaBell className="text-2xl cursor-pointer" /> */}

          {/* User Profile */}
          <div className="relative" ref={dropdownRef}>
            {userData ? (
              <>
                <img
                  src={
                    userData.profileImage ||
                    `https://server.ramakumaritrades.co.in/storage/userdp/${userData.image}`
                  }
                  alt="Profile"
                  className="w-10 h-10 rounded-full cursor-pointer"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg">
                    <div className="px-4 py-2 text-sm font-semibold">
                      {userData.employee_name}
                    </div>
                    <hr />
                    <button
                      onClick={() => {
                        toggleDropdown();
                        handleLogout();
                      }}
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

// 

import React, { useEffect, useState } from "react";
import axios from "axios";

const DashboardPage = () => {
  const [counts, setCounts] = useState({
    brands: " ",
    orders: " ",
    customers: " ",
    employees: " ",
    areas: " ",
    products: " ",
  });

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Define API endpoints for each count
        const endpoints = {
          brands: "https://server.ramakumaritrades.co.in/web/brand/count-brand",
          customers: "https://server.ramakumaritrades.co.in/web/customer/count-customer",
          products: "https://server.ramakumaritrades.co.in/web/product/count-all-product",
          orders: "https://server.ramakumaritrades.co.in/web/order/count-all-orders",
          areas: "https://server.ramakumaritrades.co.in/web/area/count-area",
          employees: "https://server.ramakumaritrades.co.in/web/employee/count-employee",
        };

        // Make requests to each API endpoint
        const requests = Object.entries(endpoints).map(([key, url]) =>
          axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(response => ({
            key,
            count: response.data.data, // Assuming the count is in `response.data.data`
          }))
        );

        // Wait for all requests to complete
        const results = await Promise.all(requests);

        // Update state with results
        const updatedCounts = results.reduce((acc, { key, count }) => {
          acc[key] = count;
          return acc;
        }, {});

        setCounts((prevCounts) => ({
          ...prevCounts,
          ...updatedCounts,
        }));
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
  }, [token]);

  return (
    <div className="flex flex-col h-[calc(100vh-6rem)] overflow-hidden md:flex-row">
  <div className="flex-1 p-6 bg-gray-100 overflow-auto">
    <div className="mb-6">
      <h1 className="text-2xl font-bold text-gray-700 md:text-3xl">Dashboard Overview</h1>
    </div>

    {/* Summary Cards */}
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {/* Brands */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Brands</h3>
        <p className="text-3xl md:text-5xl">{counts.brands}</p>
      </div>

      {/* Customers */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Customers</h3>
        <p className="text-3xl md:text-5xl">{counts.customers}</p>
      </div>

      {/* Employees */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Employees</h3>
        <p className="text-3xl md:text-5xl">{counts.employees}</p>
      </div>

      {/* Orders */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Orders</h3>
        <p className="text-3xl md:text-5xl">{counts.orders}</p>
      </div>

      {/* Products */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Products</h3>
        <p className="text-3xl md:text-5xl">{counts.products}</p>
      </div>

      {/* Areas */}
      <div className="bg-white p-6 rounded-lg shadow-md text-center">
        <h3 className="text-lg font-semibold mb-4 md:text-xl">Areas</h3>
        <p className="text-3xl md:text-5xl">{counts.areas}</p>
      </div>
    </div>
  </div>
</div>

  );
};

export default DashboardPage;

import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash ,FaTimes} from 'react-icons/fa';
import * as XLSX from 'xlsx'; // For Excel download
import axios from 'axios';

function ProductPage() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    product_name: '',
    brand_name: '',
    brand_id: '',
    units: '',
    purchase_price: '',
    sale_price: '',
    quantity: '',
    stock_status: '',

  });
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('all');
  const [filteredProducts, setFilteredProducts] = useState([]);


  const token = localStorage.getItem("accessToken");


  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://server.ramakumaritrades.co.in/web/product/get-all-product', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch products. Status: ' + response.status);
      }

      const data = response.data;
      setProducts(response.data.data || []);
      localStorage.setItem('product_id', JSON.stringify(data.data.map(products => products.product_id)));

    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [token]);

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };


  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const toggleAddModal = () => {
    setFormData(products || {
      product_name: '',
      brand_name: '',
      brand_id: '',
      units: '',
      purchase_price: '',
      sale_price: '',
      quantity: '',
      stock_status: '',

    });
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = (product) => {
    setSelectedProduct(product);
    setFormData(product || {
      product_name: '',
      brand_name: '',
      brand_id: '',
      units: '',
      purchase_price: '',
      sale_price: '',
      quantity: '',
      stock_status: '',

    });
    setShowEditModal(!showEditModal);
  };


  // add product....

  const handleAddProduct = async (e) => {
    e.preventDefault();

    // Create a FormData object to handle both text data and file uploads
    const productData = new FormData();

    // Append the form data fields and convert non-string fields to strings
    productData.append('product_name', formData.product_name);
    productData.append('brand_name', formData.brand_name);
    productData.append('brand_id', formData.brand_id);
    productData.append('purchase_price', formData.purchase_price);
    productData.append('units', formData.units);
    productData.append('sale_price', formData.sale_price);
    productData.append('quantity', formData.quantity);
    productData.append('stock_status', formData.stock_status);

    // Append the product image file (if provided)
    if (formData.product_image) {
      productData.append('product_image', formData.product_image);
    }


    try {
      const response = await axios.post(
        'https://server.ramakumaritrades.co.in/web/product/create-product',
        productData, // Send the form data
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure correct content type for file upload
            'Authorization': `Bearer ${token}`, // Include token if necessary
          },
        }
      );

      // Handle success (e.g., reset the form or close the modal)
      console.log('Product added successfully:', response.data);

      if (response.data.success) {
        // Update the product list state and close modal
        setProducts([...products, response.data.data]);
        toggleAddModal();
      } else {
        console.error('Failed to add product:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  // csv file

  const handleUploadCsv = async () => {
    if (!csvFile) {
      console.error("No CSV file selected");
      return;
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error("Authorization token is missing.");
      return;
    }

    try {
      // Prepare FormData with the file
      const formData = new FormData();
      formData.append('csv_file', csvFile); // Appending CSV file with the correct key "csv_file"

      // Send the POST request to bulk upload the products
      const response = await fetch('https://server.ramakumaritrades.co.in/web/product/bulk-upload-products', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
          // No need to set Content-Type; it will be set automatically with FormData
        }
      });

      // Check if the upload was successful
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          throw new Error(`Client Error: ${response.statusText}`);
        } else if (response.status >= 500) {
          throw new Error(`Server Error: ${response.statusText}`);
        }
        throw new Error(`Failed to upload CSV file: ${response.statusText}`);
      }

      const data = await response.json(); // Server response
      console.log("Server response:", data);

      // Assuming fetchProducts() reloads product data in the UI
      alert("CSV file uploaded and products added successfully.");
      fetchProducts();

    } catch (error) {
      if (error.message === "Failed to fetch") {
        console.error('Network error. Ensure the server is running and CORS is properly configured.');
      } else {
        console.error('Error during CSV upload:', error.message);
      }
      alert('An error occurred during the upload process.');
    }
  };


  // edit products

  const handleEditProduct = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      // Append form data fields only if they have values

      if (formData.product_name) formDataToSend.append('product_name', formData.product_name);
      if (formData.product_image) formDataToSend.append('product_image', formData.product_image); // Optional image upload
      if (formData.brand_name) formDataToSend.append('brand_name', formData.brand_name);
      if (formData.brand_id) formDataToSend.append('brand_id', formData.brand_id);
      if (formData.units) formDataToSend.append('units', formData.units);
      if (formData.purchase_price) formDataToSend.append('purchase_price', formData.purchase_price);
      if (formData.sale_price) formDataToSend.append('sale_price', formData.sale_price);
      if (formData.quantity) formDataToSend.append('quantity', formData.quantity);
      if (formData.stock_status) formDataToSend.append('stock_status', formData.stock_status);

      // Always include the product_id in the update
      formDataToSend.append('product_id', selectedProduct.product_id);

      const response = await fetch(`https://server.ramakumaritrades.co.in/web/product/update-product`, {
        method: 'PUT',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`, // Authorization header for authentication
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update product.');
      }

      const data = await response.json();

      if (data.success) {
        // Update the products state based on product_id
        setProducts(products.map(product =>
          product.product_id === selectedProduct.product_id
            ? {
              ...product,
              product_name: formData.product_name || product.product_name,
              brand_name: formData.brand_name || product.brand_name,
              brand_id: formData.brand_id || product.brand_id,
              purchase_price: formData.purchase_price || product.purchase_price,
              weight: formData.weight || product.weight,
              price: formData.price || product.price,
              quantity: formData.quantity || product.quantity,
              current_price: formData.current_price || product.current_price,
              sale_price: formData.sale_price || product.sale_price,
              stock_status: formData.stock_status || product.stock_status,
              product_image: formData.product_image
            }
            : product
        ));
        toggleEditModal(false); // Close the edit modal after successful update
        fetchProducts();

      } else {
        console.error('Failed to update product:', data.message);
      }
    } catch (error) {
      console.error('Error updating product:', error.message);
    }
  };

  // delete product

  const handleDeleteProduct = async (product_id) => {
    console.log(`Deleting product with ID: ${product_id}`);

    // Check if the product_id is valid
    if (!product_id) {
      console.error('Invalid product_id:', product_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        // Save the product_id to localStorage (optional step, based on your use case)
        localStorage.setItem('product_id', product_id);

        const response = await fetch("https://server.ramakumaritrades.co.in/web/product/delete-product-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            product_id: product_id, // Send product_id in the request body
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization token for authentication
          },
        });

        // Check for a successful response
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete product.');
        }

        const data = await response.json();
        if (data.success) {
          // Update the products state to remove the deleted product
          setProducts(prevProducts => prevProducts.filter(product => product.product_id !== product_id));
          console.log('Product deleted successfully');
        } else {
          console.error('Failed to delete product:', data.message);
        }
      } catch (error) {
        console.error('Error deleting product:', error.message);
      }
    }
  };

  // excel dowmload

  const handleDownloadExcel = () => {
    const dataToExport = products.map(product => ({
      'Product Id': product.product_id,
      'Product Name': product.product_name,
      'Brand Name': product.brand_name,
      'Brand Id': product.brand_id,
      'Customer Id': product.customer_id,
      'Units': product.units,
      'Quantity': product.quantity,
      'Purchase Price': product.purchase_price,
      'Sale Price': product.sale_price,
      'Stock': product.stock_status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
    XLSX.writeFile(workbook, 'products.xlsx');
  };

  const filteredProductss = products.filter((product) => {
    const productName = product.product_name || ''; // Ensure product_name is not undefined
    return productName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // get all brands

  const fetchBrands = async () => {
    try {
      const response = await axios.get('https://server.ramakumaritrades.co.in/web/brand/get-all-brand', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch brands. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setBrands(data.data || []);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [token]);

   // Handle brand selection
   const handleBrandChange1 = (e) => {
    const brandId = e.target.value;
    setSelectedBrandId(brandId);
    applyFilters(brandId, searchTerm);
  };

  const handleBrandChange = (event) => {
    const selectedBrandId = event.target.value;
    const selectedBrand = brands.find(brand => brand.brand_id === selectedBrandId);
  
    setFormData({
      ...formData,
      brand_name: selectedBrand ? selectedBrand.brand_name : '',
      brand_id: selectedBrandId
    });
  };
  
  // Handle search input
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    applyFilters(selectedBrandId, term);
  };

  // Apply filters for brand and search
  const applyFilters = (brandId, term) => {
    if (!products.length) {
      console.log("No products available to filter.");
      return;
    }
  
    const filtered = products.filter((product) => {
      const matchesBrand = brandId === 'all' || product.brand_id.toString() === brandId;
      const matchesSearch = product.product_name?.toLowerCase().includes(term);
      return matchesBrand && matchesSearch;
    });
  
    console.log("Filtered Products:", filtered);
    setFilteredProducts(filtered);
  };
  
  // Call this function after `fetchProducts` ensures products are loaded
  useEffect(() => {
    if (products.length) {
      applyFilters(selectedBrandId, searchTerm);
    }
  }, [products]);
    




  return (
    <div className="flex flex-col h-[calc(100vh-6rem)] overflow-hidden ">
    {/* Top Section */}
    <div className="flex flex-wrap justify-between gap-2 mb-4">
      {/* File Upload Section */}
      <div className="flex flex-wrap items-center gap-2">
        <input
          type="file"
          accept=".csv"
          onChange={handleCsvChange}
          className="border border-gray-300 rounded px-2 py-2 w-full sm:w-64"
        />
        <button
          onClick={handleUploadCsv}
          className="bg-navy text-white px-2 py-2 rounded w-full sm:w-auto"
        >
          Upload Products
        </button>
      </div>
  
      {/* Action Buttons */}
      <div className="flex flex-wrap items-center gap-2">
        <button
          onClick={toggleAddModal}
          className="bg-navy text-white px-2 py-2 rounded w-full sm:w-auto"
        >
          Add Product
        </button>
        <button
          onClick={handleDownloadExcel}
          className="bg-navy text-white px-2 py-2 rounded w-full sm:w-auto"
        >
          Download Excel
        </button>
      </div>
    </div>
  
    {/* Search Section */}

    <div className="flex flex-wrap justify-between">
        {/* Brand Selector */}
        <div className="w-96">
          <select
            name="brand_name"
            value={selectedBrandId}
            onChange={handleBrandChange1}
            className="border border-gray-300 rounded px-2 py-2 w-full"
          >
            <option value="all">All Products</option>
            {brands.map((brand) => (
              <option key={brand.brand_id} value={brand.brand_id}>
                {brand.brand_name}
              </option>
            ))}
          </select>
        </div>

        {/* Search Bar */}
        <div className="flex items-center border border-gray-300 rounded w-96 sm:w-1/2 mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by product name"
            className="rounded px-2 py-2 w-full"
          />
        </div>
      </div>

{/* Product Table */}
<div className="w-[calc(100vw-14rem)] overflow-x-auto h-[calc(100vh-4rem)] overflow-y-auto">
        <table className="table-auto min-w-[1700px]">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="border-b px-2 py-2">S.NO</th>
              <th className="border-b px-2 py-2">Product Id</th>
              <th className="border-b px-2 py-2">Product Name</th>
              <th className="border-b px-2 py-2">Brand Name</th>
              <th className="border-b px-2 py-2">Brand Id</th>
              <th className="border-b px-2 py-2">Units</th>
              <th className="border-b px-2 py-2">Quantity</th>
              <th className="border-b px-2 py-2">Purchase Price</th>
              <th className="border-b px-2 py-2">Sale Price</th>
              <th className="border-b px-2 py-2">Stock Status</th>
              <th className="border-b px-2 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={product.product_id}>
                <td className="border-b px-2 py-2">{index + 1}</td>
                <td className="border-b px-2 py-2">{product.product_id}</td>
                <td className="border-b px-2 py-2">{product.product_name}</td>
                <td className="border-b px-2 py-2">{product.brand_name}</td>
                <td className="border-b px-2 py-2">{product.brand_id}</td>
                <td className="border-b px-2 py-2">{product.units}</td>
                <td className="border-b px-2 py-2">{product.quantity}</td>
                <td className="border-b px-2 py-2">{product.purchase_price}</td>
                <td className="border-b px-2 py-2">{product.sale_price}</td>
                <td className="border-b px-2 py-2">{product.stock_status}</td>
                <td className="border-b px-2 py-2 text-center">
                  <button
                    onClick={() => toggleEditModal(product)}
                    className="text-navy hover:text-blue-800 mx-2 "
                  >
                    <FaEdit size={16} />
                  </button>
                  <button
                    onClick={() => handleDeleteProduct(product.product_id)}
                    className="text-red-600 hover:text-red-800 mx-2"
                  >
                    <FaTrash size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {/* Add Product Modal */}
     
       {showAddModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between">
              <h2 className="text-xl mb-4">Add New Product</h2>
              <button
                type="button"
                onClick={toggleAddModal}
                className="bg-red-500 text-white px-4 py-2 rounded ml-4"
              >
                <FaTimes size={16} />
              </button>
            </div>

            <form onSubmit={(e) => handleAddProduct(e, formData)}>
              <div className="grid grid-cols-2 gap-4">
                {/* Product Name */}
                <div>
                  <label>Product Name:</label>
                  <input
                    type="text"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Brand Name Dropdown */}
                <div>
                  <label>Brand Name:</label>
                  <select
                    name="brand_name"
                    value={formData.brand_id}
                    onChange={handleBrandChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="" disabled>Select a brand</option>
                    {brands.map((brand) => (
                      <option key={brand.brand_id} value={brand.brand_id}>
                        {brand.brand_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Brand ID Input */}
                <div>
                  <label>Brand ID:</label>
                  <input
                    type="text"
                    name="brand_id"
                    value={formData.brand_id}
                    readOnly
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Units Dropdown */}
                <div>
                  <label>Units:</label>
                  <select
                    name="units"
                    value={formData.units}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="">Select Unit</option>
                    <option value="Kg">Kg</option>
                    <option value="Box">Box</option>
                    <option value="Bag">Bag</option>
                    <option value="Dozen">Dozen</option>
                    <option value="Pieces">Pieces</option>
                  </select>
                </div>

                {/* Quantity Input */}
                <div>
                  <label>Quantity:</label>
                  <input
                    type="text"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Purchase Price Input */}
                <div>
                  <label>Purchase Price:</label>
                  <input
                    type="text"
                    name="purchase_price"
                    value={formData.purchase_price}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Sale Price Input */}
                <div>
                  <label>Sale Price:</label>
                  <input
                    type="text"
                    name="sale_price"
                    value={formData.sale_price}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Stock Status Dropdown */}
                <div>
                  <label>Stock:</label>
                  <select
                    name="stock_status"
                    value={formData.stock_status}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="">Select Stock</option>
                    <option value="available">Available</option>
                    <option value="unavailable">Unavailable</option>
                  </select>
                </div>
              </div>

              <div className="mt-4 flex justify-end gap-4">
                <button
                  type="submit"
                  className="bg-navy text-white px-4 py-2 rounded"
                >
                  ADD
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Product Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center  items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <div className=' flex justify-between'>
            <h2 className="text-xl mb-4">Edit Product</h2>
            <button
                  type="button"
                  onClick={() => toggleEditModal(null)}
                  className="bg-red-500 text-white px-4 py-2 rounded ml-4"
                >
                  <FaTimes size={16} />
                </button>
                </div>
            <form onSubmit={handleEditProduct}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>Product Name:</label>
                  <input
                    type="text"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Brand Name:</label>
                  <input
                    type="text"
                    name="brand_name"
                    value={formData.brand_name}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Brand ID:</label>
                  <input
                    type="text"
                    name="brand_id"
                    value={formData.brand_id}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label> Units:</label>
                  <input
                    type="text"
                    name="units"
                    value={formData.units}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Purchase Price:</label>
                  <input
                    type="text"
                    name="purchase_price"
                    value={formData.purchase_price}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

               
                <div>
                  <label>Quantity:</label>
                  <input
                    type="text"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Sale Price:</label>
                  <input
                    type="text"
                    name="sale_price"
                    value={formData.sale_price}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Stock:</label>
                  <select
                    name="stock_status"
                    value={formData.stock_status}
                    onChange={handleInputChange}

                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="">Select Stock</option>
                    <option value="available">Available</option>
                    <option value="unavailable">Unavailable</option>

                  </select>
                </div>

              </div>
              <div className=" mt-4 flex justify-end gap-4">
                <button
                  type="submit"
                  className="bg-navy text-white px-4 py-2 rounded"
                >
                  Save 
                </button>
                
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}

export default ProductPage;
